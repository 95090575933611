<script>

	import { onMount } from 'svelte';
	import { fade } from "svelte/transition";

	import * as Sentry from "@sentry/browser";

	import moment from 'moment-timezone';

	import { v4 as uuid } from 'uuid';

	import { colord } from "colord";

	import Login from './Login.svelte';
	import Hold from './Hold.svelte';

	import Masthead from './Masthead.svelte';
	import InfoPanel from './InfoPanel.svelte';
	import Announcements from './Announcements.svelte';
	import VideoCall from './VideoCall.svelte';

	import Lobby from './Lobby.svelte';
	import Auditorium from './Auditorium.svelte';
	import Exhibition from './Exhibition.svelte';
	import Lounge from './Lounge.svelte';
	// import Backstage from './Backstage.svelte';
	import Studio from './Studio.svelte';

	import Spinner from './ui/Spinner.svelte';
	import BigError from './ui/BigError.svelte';

	import { getServerData, postServerData } from './lib/prelude.js';
	import { callStatus } from './lib/conversations.js';

	import {
		attendeeToken,
		csrfToken,
		busy,
		bigError,
		spinnerColor,
		spinnerInDelay,
		spinnerInDuration,
		spinnerOutDelay,
		spinnerOutDuration,
		attendee,
		event,
		utcDiff,
		room,
		leftSection,
		leftDocked,
		showLeft,
		rightDocked,
		agenda,
		sessions,
		speakers,
		sponsors,
		exhibitors,
		syncClient,
		syncChannels,
		attendeeLikes,
		muted,
		shadowbans,
		attendees,
		epoch,
		epochInterval,
		backstage,
		ejected,
		socialMediaServiceOpts,
		lobby,
		isPhone,
		announcements,
		videoCall,
		currentSessions,
		studioSessions,
		studioSessionToken,
		session
	} from './lib/stores.js';

	import { now, getEpoch, fromUTC } from './lib/dt.js';
	import { randomIntFromInterval } from './lib/utils.js';

	let mounted = false;
	let twilioLoaded = false;

	let syncToken;

	let bgFade = 0;
	let bgBlur = 0;

	let isOnline = true;

	let lobbyRefresh = null;
	let stageInteractions = true;

	let vh;

	let checkinThreshold = null;

	const regex = /\/(live)?\/?/i;
	const token = window.location.pathname.replace(regex,'');

	const bleep = new Audio('https://virtual.attendzen.io/bleep.mp3');

	if (token) {
		if (token.includes('/')) {
			const tokens = token.split('/');
			$attendeeToken = tokens[0];
			$studioSessionToken = tokens[1];
		} else if (token.length == 6) {
			$studioSessionToken = token;
		} else {
			$attendeeToken = token;
		}
	} else if ($attendeeToken) {
		let url = window.location.href;
		url = url.endsWith('/') ? url + $attendeeToken : url + '/' + $attendeeToken;
		window.location.href = url;
	}

	onMount(async () => {

		window.addEventListener('offline', function(e) {
			isOnline = false;
		});

		window.addEventListener('online', function(e) {
			isOnline = true;
			refreshSyncToken();
		});

		document.body.addEventListener('click', unlockAudio);
		document.body.addEventListener('touchstart', unlockAudio);

		$epoch = getEpoch();

		$epochInterval = setInterval(() => {
			// if ($epoch % 60) {
			if ($epoch % 10) {
				$epoch = $epoch + 1;
			} else {
				// console.log('epoch drift avoider...')
				$epoch = getEpoch();
			}
		}, 1000);

		$busy = true;

		// const offset = new Date().getTimezoneOffset();
		// console.log(Intl.DateTimeFormat().resolvedOptions().timeZone, offset);

		const fadeTimeout = setTimeout(function(){
			bgFade = 400;
		}, 400);

		let now = moment.utc().format();

		// We start with a GET request.  This returns the event and timezone details, allowing us to present the prefs interstitial.
		// However, if we've sent auth (cookie + csrf), the response will also contain the full payload, bypassing the later POST.

		// 12 Apr 2022 - we no longer have the interstitial, but we still split the response into a GET and a POST
		// The idea is that this gets us the event data (branding) quickly even if the full payload takes a few moments

		const authData = await getServerData('virtual/auth', {
			token: $attendeeToken,
			studioSessionToken: $studioSessionToken,
			now: now
		});

		clearTimeout(fadeTimeout);

		if (authData && authData.event) {

			if (($bigError == 'Bad access token') && (!$attendeeToken)) {
				$bigError = null;
			}

			setEvent(authData.event);

			if (authData.utcDiff) {
				$utcDiff = authData.utcDiff;
			}

			if (!bgFade && $event.theme.virtual.bgImage) {
				bgFade = 800;
			}

			if ($event.theme.virtual.bgBlur) {
				bgBlur = parseInt($event.theme.virtual.bgBlur);
			}

			if (!$bigError) {

				if (authData.attendee) {

					handleAuthPayload(authData);

				} else {

					// Unless we have a studioSessionToken only
					if (!($studioSessionToken && !$attendeeToken)) {
						const checkinOffset = $event.setup.virtual.checkinOffset ? parseInt($event.setup.virtual.checkinOffset) : 3600;
						checkinThreshold = $event.startsEpoch - checkinOffset;
					}

					if (($epoch > checkinThreshold) || (authData.studio && authData.studio.length)) {
						if ($attendeeToken) {
							await checkIn();
						}
					}

				}

				$busy = false;

    			const match = window.matchMedia || window.msMatchMedia;

				if (match) {
					const mq = match("(max-width: 600px), (max-height: 600px)");
					$isPhone = mq.matches;
					// console.log('isPhone', $isPhone);
				}

				if ($studioSessionToken && $studioSessions && $agenda && $agenda.sessions) {
					let verify = [];
					for (const s of $studioSessions) {
						verify[s] = true;
					}
					for (const s of $agenda.sessions) {
						const sessionToken = btoa(s.ref).slice(0,6);
						if ($studioSessionToken == sessionToken) {
							if (verify[s.ref]) {
								$session = s;
								$room = 'studio';
								checkinThreshold = null;
								break;
							}
						}
					}
				}

				mounted = true;

				setTimeout(() => {
					announceSessionsInProgress();
				}, 200);

			}

		} else {

			// window.location.assign('https://' + window.location.hostname);

		}

	});

	function setEvent(eventData) {

		$event = eventData;

		document.title = $event.name;

		['textColor','panelColor','accentColor','captionColor','buttonTextColor','bgColor','logoColor'].forEach(c => {
			if ($event.theme.virtual[c]) document.body.style.setProperty('--' + c, $event.theme.colors[$event.theme.virtual[c]]);
		});

		// $spinnerColor = $event.theme.colors[$event.theme.virtual['accentColor']];
		$spinnerColor = $event.theme.colors[$event.theme.virtual['panelColor']];

		['05','10','20','40','60','80','90','120'].forEach(b => {
			if ($event.theme.virtual['blend-' + b]) document.body.style.setProperty('--blend-' + b, $event.theme.virtual['blend-' + b]);
			if ($event.theme.virtual['accentBlend-' + b]) document.body.style.setProperty('--accentBlend-' + b, $event.theme.virtual['accentBlend-' + b]);
		});

		['red','red-120','bgBlur','bgOpacity','bgScale'].forEach(s => {
			if ($event.theme.virtual[s]) document.body.style.setProperty('--' + s, $event.theme.virtual[s]);
		});

		let tc = $event.theme.colors[$event.theme.virtual.textColor];
		let hsl = colord(tc).toHsl();
		hsl.l = '15';
		let shadow = colord(hsl).alpha(0.4).toRgbString();
		document.body.style.setProperty('--shadow', shadow);

	}

	async function checkIn() {

		if ($attendeeToken) {

			$busy = true;

			const authData = await postServerData('virtual/auth', {
				token: $attendeeToken,
				// publicAttendee: $appearInAttendeeList
			});

			if (authData && authData.ejected) {

				$bigError = 'ejected';
				$ejected = authData.ejected;
				$busy = false;

			} else if (authData && authData.attendee) {

				handleAuthPayload(authData);

				$busy = false;

			}

		} else {

			checkinThreshold = null;

		}

	}

	function handleAuthPayload(authData) {

		if (authData.csrfToken) {
			// console.log("setting csrf", authData.csrfToken);
			$csrfToken = authData.csrfToken;
		}

		if (authData.attendee) {
			$attendee = authData.attendee;
			Sentry.setUser($attendee);
		}

		if (authData.syncToken) {
			syncToken = authData.syncToken;
		}

		if (authData.syncChannels) {
			$syncChannels = authData.syncChannels;
		}

		if (authData.agenda) {
			setAgenda(authData.agenda);
		}

		if (authData.speakers) {
			$speakers = authData.speakers;
		}

		if (authData.sponsors) {
			$sponsors = authData.sponsors;
		}

		if (authData.exhibitors) {
			$exhibitors = authData.exhibitors;
		}

		if (authData.likes) {
			$attendeeLikes = authData.likes;
		}

		if (authData.muted) {
			$muted = authData.muted;
		}

		if (authData.shadowbans) {
			$shadowbans = authData.shadowbans;
		}

		if (authData.socialmediaservices) {
			$socialMediaServiceOpts = authData.socialmediaservices;
		}

		if (authData.lobby) {
			$lobby = authData.lobby;
		}

		if (authData.studio) {
			$studioSessions = authData.studio;
		}

		// if (!$leftSection) {
		// 	if ($event.setup.virtual.defaultTab) {
		// 		if (($event.setup.virtual.defaultTab == 'sponsors') && ($sponsors && $sponsors.sponsors && $sponsors.sponsors.length)) {
		// 			$leftSection = 'sponsors';
		// 		} else {
		// 			$leftSection = 'agenda';
		// 		}
		// 	} else {
		// 		$leftSection = 'agenda';
		// 	}
		// } else if (($leftSection == 'attendees') && !($event && $event.setup && $event.setup.virtual && $event.setup.virtual.attendeeList)) {
		// 	$leftSection = 'agenda';
		// } else if (($leftSection == 'speakers') && !($speakers && $speakers.speakers && $speakers.speakers.length)) {
		// 	$leftSection = 'agenda';
		// }

		$leftSection = $event.setup.virtual.defaultTab ? $event.setup.virtual.defaultTab : 'agenda';

		if ($event.setup.virtual.infoPanel && !$event.setup.virtual.infoPanel.length) $leftDocked = true;

		checkinThreshold = null;

	}

	function setAgenda(a) {

		// console.log('Setting agenda', a);

		$agenda = a;

		let newSessions = {};
		let newBackstage = [];

		let currentSession = null;

		const tz = $event.setup.timeZone;
		const cutoff = moment.utc($event.ends).unix();

		$event.epochEnds = moment.utc($event.starts).unix();

		for (let s of $agenda.sessions) {

			s.starts = fromUTC(s.starts,tz);
			s.ends = fromUTC(s.ends,tz);
			s.epochStarts = s.starts.utc().unix();
			s.epochEnds = s.ends.utc().unix();

			// console.log('setAgenda session',s);

			if (s.virtual && s.virtual.isVirtual && (s.epochStarts < cutoff)) {

				if (s.epochEnds > $event.epochEnds) {
					$event.epochEnds = s.epochEnds;
				}

				if (!newSessions[s.epochStarts]) newSessions[s.epochStarts] = [];
				newSessions[s.epochStarts].push(s);

				if ($attendee.type == 'login') {
					newBackstage.push(s);
				} else if (s.virtual.backstage && s.virtual.backstage.length) {
					for (const b of s.virtual.backstage) {
						if (b.attendee && (b.attendee == $attendee.ref)) {
							newBackstage.push(s.ref);
							break;
						}
					}
				}

			}

			if ($session && ($session.ref == s.ref)) {
				currentSession = s;
			}

		}

		$sessions = newSessions;
		$backstage = newBackstage;

		$session = currentSession;

	}

	async function refreshSyncToken() {
		const refreshData = await getServerData('virtual/auth/refresh');
		if (!$bigError) {
			if (refreshData.syncToken) {
				syncToken = refreshData.syncToken;
				$syncClient.updateToken(syncToken);
				// console.log('Sync token REFRESHED');
			}
		}
	}

	function setTwilioLoaded() {
		twilioLoaded = true;
	}

	async function twilioConnect() {
		if (!$syncClient) {

			$syncClient = new Twilio.Sync.Client(syncToken);

			$syncClient.on('connectionError', (connectionError) => {
				console.error('Connection was interrupted:', connectionError.message);
				console.error('Is terminal:', connectionError.terminal);
				if (connectionError.terminal && isOnline) {
					refreshSyncToken();
				}
			});

			$syncClient.on('tokenAboutToExpire', () => {
				// console.log('Sync token about to expire -- RENEWING');
				refreshSyncToken();
			});

			try {

				let eventNotifications = await $syncClient.list($syncChannels.event);

				eventNotifications.on('itemAdded', syncItemAdded);
				eventNotifications.on('itemRemoved', syncItemRemoved);

			} catch (e) {

				console.log("Sync error", e, $syncChannels.event);

			}

			try {

				let attendeeNotifications = await $syncClient.list($syncChannels.attendee);

				attendeeNotifications.on('itemAdded', syncItemAdded);
				attendeeNotifications.on('itemRemoved', syncItemRemoved);

			} catch (e) {

				console.log("Sync error", e, $syncChannels.attendee);

			}

			try {

				let attendance = await $syncClient.map($syncChannels.attendance);

				attendance.on('itemAdded', (args) => {
					$attendees[args.item.key] = args.item.data;
					$attendees[args.item.key].seq = $attendees[args.item.key].seq ? $attendees[args.item.key].seq : args.item.dateUpdated.getTime();
					$attendees = $attendees;
				});

				attendance.on('itemRemoved', (args) => {
					delete $attendees[args.key];
					$attendees = $attendees;
				});

				attendance.on('itemUpdated', (args) => {
					$attendees[args.item.key] = args.item.data;
					$attendees = $attendees;
				});

				getAttendance(attendance);

			} catch (e) {

				console.log("Sync error", e, $syncChannels.attendance);

			}

		}
	}

	function attendeesPageHandler(paginator) {
		paginator.items.forEach((item) => {
			$attendees[item.key] = item.data;
			$attendees[item.key].seq = $attendees[item.key].seq ? $attendees[item.key].seq : item.dateUpdated.getTime();
		});
		// console.log('attendees',attendees);
		$attendees = $attendees;
		return paginator.hasNextPage
			? paginator.nextPage().then(attendeesPageHandler)
			: null;
	}

	function getAttendance(attendance) {
		attendance.getItems({ pageSize: 100 })
			.then(attendeesPageHandler)
			.catch((error) => {
				if (error.message.match('Maximum attempt')) {
					// back off and try again later...
					const smear = 1000 * randomIntFromInterval(5,10);
					setTimeout(() => {
						getAttendance(attendance);
					}, smear);
				} else {
					console.error('getAttendance failed', error);
				}
			});
	}

	function syncItemAdded(message) {

		// It's message.item.data for lists and maps,
		// but message.data for documents...

		const messageData = message.item.data;

		// console.log('notification', messageData);

		if (messageData && messageData.type && (messageData.type == 'system')) {

			if (messageData.hook && (messageData.hook == 'agenda')) {

				const numAttendees = Object.keys($attendees).length;
				const smearDuration = Math.ceil(numAttendees / 100) + 2;
				const smear = 1000 * randomIntFromInterval(0,smearDuration);
				console.log('smearing agenda fetch', smear);

				setTimeout(async () => {
					const agendaData = await getServerData('virtual/agenda');
					if (!$bigError) {
						if (agendaData.agenda) setAgenda(agendaData.agenda);
					}
				}, smear);

			} else if (messageData.hook && (messageData.hook == 'lobby')) {

				if (!lobbyRefresh) {
					const smear = 1000 * randomIntFromInterval(2,30);
					// console.log('smearing', smear);
					lobbyRefresh = setTimeout(async () => {
						const lobbyData = await getServerData('virtual/lobby');
						if (!$bigError) {
							if (lobbyData.lobby) $lobby = lobbyData.lobby;
							lobbyRefresh = null;
						}
					}, smear);
				}

			} else if (messageData.hook && (messageData.hook == 'settings')) {

				const smear = 1000 * randomIntFromInterval(0,10);
				// console.log('smearing', smear);
				setTimeout(async () => {
					const eventData = await getServerData('virtual/auth/settings');
					if (!$bigError) {
						if (eventData.event) setEvent(eventData.event);
					}
				}, smear);

			}

		} else if (messageData && messageData.type && (messageData.type == 'announcement')) {
			$announcements.push(messageData);
			$announcements = $announcements;
			bleep.play();

		} else if (messageData && messageData.type && (messageData.type == 'like')) {
			$attendeeLikes.push(messageData.hook);
			$attendeeLikes = $attendeeLikes;

		} else if (messageData && messageData.type && (messageData.type == 'unlike')) {
			$attendeeLikes = $attendeeLikes.filter(l => { l != messageData.hook });

		} else if (messageData && messageData.type && (messageData.type == 'mute')) {
			if (!$muted.includes(messageData.mutee)) {
				$muted.push(messageData.mutee);
				$muted = $muted;
			}

		} else if (messageData && messageData.type && (messageData.type == 'unmute')) {
			$muted = $muted.filter(l => { l != messageData.mutee });

		} else if (messageData && messageData.type && (messageData.type == 'shadowban')) {
			if (!$shadowbans.includes(messageData.attendee)) {
				$shadowbans.push(messageData.attendee);
				$shadowbans = $shadowbans;
			}

		} else if (messageData && messageData.type && (messageData.type == 'unshadowban')) {
			$shadowbans = $shadowbans.filter(l => { l != messageData.attendee });

		} else if (messageData && messageData.type && (messageData.type == 'eject')) {
			$ejected = messageData.message;
			$bigError = 'ejected';

		} else if (messageData && messageData.type && (messageData.type == 'uneject')) {
			$bigError = null;
			$ejected = null;
			checkIn();

		} else if (messageData && messageData.type && (messageData.type == 'videocall')) {
			if ((messageData.call != undefined) && messageData.status && (messageData.status == 'incoming')) {
				if ($videoCall && ($videoCall.call != messageData.call)) {
					callStatus(messageData.call, 'ended');
				} else {
					$announcements.push({
						ref: messageData.call,
						type: 'videocall',
						jwt: messageData.jwt,
						participant: messageData.participant
					});
					$announcements = $announcements;
					bleep.play();
				}
			} else if ((messageData.call != undefined) && messageData.status && (messageData.status == 'ended')) {
				if ($videoCall && ($videoCall.call == messageData.call)) {
					$videoCall.status = 'ended';
				} else {
					let filtered = $announcements.filter(a => a.ref != messageData.call);
					$announcements = filtered;
				}
			}

		} else if (messageData && messageData.type && (messageData.type == 'report')) {
			if ($room != 'studio') {
				if (messageData.report.reporter != $attendee.ref) {
					$announcements.push({
						ref: messageData.report.ref,
						type: 'report',
						report: messageData.report
					});
					$announcements = $announcements;
					bleep.play();
				}
			}

		} else {

			// console.log('notification type', messageData.type);

		}

	}

	function syncItemRemoved(message) {

		const messageData = message.previousItemData;

		// console.log('notification removed', messageData);

	}

	function unlockAudio() {
		bleep.play();
		bleep.pause();
		bleep.currentTime = 0;
		document.body.removeEventListener('click', unlockAudio);
		document.body.removeEventListener('touchstart', unlockAudio);
		// console.log('audio unlocked?');
	}

	function announceSessionsInProgress() {

		// 28 Jul 2023 -- Handle latecomers
		// 24 Nov 2023 -- if there's just one session, automatically move to the stage

			// console.log('announceSessionsInProgress', $room, $currentSessions);

		if ($currentSessions.length && ($room != 'auditorium') && ($room != 'studio')) {

			// console.log('hello latecomer', $room);

			if ((Object.keys($sessions).length == 1) && ($sessions[Object.keys($sessions)[0]].length == 1) && ($room == 'lobby')) {

				// Single-session event, and we're in the lobby...
				// console.log('auto-move to stage...');
				
				$room = 'auditorium';
				$session = $sessions[Object.keys($sessions)[0]][0];

			} else {

				const autoCancel = $epoch + 120;

				// console.log('epoch', $epoch, autoCancel);

				$announcements.push({
					ref: uuid(),
					type: 'session',
					sessions: $currentSessions,
					dt: $epoch,
					autoCancel: autoCancel
				});

				$announcements = $announcements;

			}

		}

	}

	$: if (twilioLoaded && syncToken) {
		twilioConnect();
	}

	$: if (vh) {
		document.body.style.setProperty('--vh', vh + 'px');
	}

	// $: console.log('$rightDocked', $rightDocked);

</script>

<style>
	:global(body) {
		display: flex;
		flex-direction: column;
		/*height: 100vh;*/
		/*height: 100dvh;*/
		/*height: -webkit-fill-available;*/
		/*height: 100%;*/
		height: var(--vh, 100vh);
		min-height: 600px;
	}

	@media (min-width: 600px) and (min-height: 600px) {
		:global(body) {
			overflow: hidden; /* for info panel tuck */
		}
	}

	.bg {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: -1;
		background: var(--bgColor, #444);
		transform: scale(var(--bgScale, 1));
	}
	.bg img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		opacity: var(--bgOpacity, 1);
		z-index: 0;
		filter: blur(var(--bgBlur, 0));
	}
	main {
		display: grid;
		grid-template-columns: auto 1fr auto;
		grid-template-areas: "l c r";
		grid-gap: 3rem 2rem;
		padding: 1.5rem 1rem;
		flex: 1 0 auto;
		height: 400px; /* for Safari */
		overflow: hidden;
	}

	/*@media (max-width: 940px) {*/
	@media (max-width: 1100px) {
		:global(#app) {
			height: auto;
		}
		main {
			grid-template-areas: "c c" "l r";
			grid-template-columns: 1fr 1fr;
			/*grid-template-rows: 1fr 1.2fr;*/
			/*grid-template-rows: 1fr 1fr;*/
			grid-template-rows: auto 1fr;
			/*grid-gap: 3rem 2rem;*/
			grid-gap: 2rem;
			height: auto;
		}
		main.room-exhibition,
		main.room-lobby {
			grid-template-areas: "c" "l";
			grid-template-columns: 1fr;
			grid-template-rows: 1fr 1fr;
			transition: margin-bottom 0.4s cubic-bezier(.24,.06,.23,.9);
		}
		main.room-lobby {
			grid-template-rows: auto 1fr;
		}
		main.room-exhibition.leftDocked {
			/*grid-template-rows: 1fr 0;*/
			margin-bottom: calc(-100vh + 4.5rem + 1rem + 1rem);
		}
	}

	@media (max-width: 600px) {
		main {
			grid-template-areas: "c" "r" "l";
			grid-template-columns: 1fr;
			grid-template-rows: 1.5fr 1fr 1fr;
			grid-gap: 1rem 0;
		}
		main.room-lobby {
			height: 110vh;
		}
		main.room-auditorium {
			height: 110vh;
			grid-template-rows: auto auto 1fr;
		}
		main.room-auditorium.hasInteractions {
			height: 140vh;
			grid-template-rows: auto 1fr 1fr;
		}
		main.room-exhibition {
			height: 140vh;
			grid-gap: 2rem;
			grid-template-rows: 1fr 0.8fr;
		}
		main.room-exhibition.leftDocked {
			margin-bottom: 0;
		}
		main.room-studio {
			grid-template-rows: auto 1fr 1fr;
		}
		main :global(.panel.docked) {
			margin-left: 0 !important;
			margin-right: 0 !important;
		}
		:global(input:focus),
		:global(textarea:focus) {
			font-size: 16px !important;
		}
	}

	@media (max-height: 600px) {
		main.room-lobby {
			height: 170vh;
		}
	}

	/*@media (max-height: 500px) and (orientation: landscape) {
		main.room-auditorium {
			grid-template-rows: 1fr 0 0;
		}
	}*/

	/* busy is now **above** overlays... */
	#busy {
		position: fixed;
		inset: 0;
		display: grid;
		place-content: center;
		z-index: 20000;
	}

	#busy:before {
		content: '';
		position: absolute;
		inset: 0;
		background: var(--textColor);
		opacity: 0.4;
	}

	#breakout {
		display: contents;
	}

	#measure {
		position: fixed;
		top: 0;
		left: 0;
		width: 0;
		height: 100%;
	}

	/*#tmp {
		position: fixed;
		bottom: 1rem;
		left: 1rem;
	}*/

</style>

<svelte:head>
  	<script type="text/javascript" src="https://media.twiliocdn.com/sdk/js/sync/releases/3.0.1/twilio-sync.min.js" on:load={setTwilioLoaded}></script>
  	<!-- <script type="module" src="https://unpkg.com/media-chrome@0.6"></script>
  	<script type="module" src="https://unpkg.com/@mux-elements/mux-video@0.4/dist/mux-video.mjs"></script> -->
  	<script type="module" src="https://cdn.jsdelivr.net/npm/playerx/+esm"></script>
  	<script type="module" src="https://cdn.jsdelivr.net/npm/media-chrome@1/+esm"></script>
  	<script type="module" src="https://cdn.jsdelivr.net/npm/@mux/mux-video@0/dist/mux-video.mjs"></script>
  	{#if $event && $event.theme && $event.theme.favicon && $event.theme.favicon.filename}
		<link rel="icon" type="image/png" href="https://cdn.attendzen.io/{$event.accountRef}/favicon32_{$event.theme.favicon.filename}" sizes="32x32"/>
		<link rel="icon" type="image/png" href="https://cdn.attendzen.io/{$event.accountRef}/favicon192_{$event.theme.favicon.filename}" sizes="192x192"/>
		<link rel="icon" type="image/png" href="https://cdn.attendzen.io/{$event.accountRef}/favicon512_{$event.theme.favicon.filename}" sizes="512x512"/>
		<link rel="apple-touch-icon" href="https://cdn.attendzen.io/{$event.accountRef}/favicon180_{$event.theme.favicon.filename}" sizes="180x180">
  	{/if}
</svelte:head>

{#if mounted}

	<div class="bg" in:fade|local={{ duration: bgFade }}>
		{#if $event.theme.virtual.bgType && ($event.theme.virtual.bgType == 'image') && $event.theme.virtual.bgImage}
			{#if $event.theme.virtual.bgImage.filename}
				{#if bgBlur > 20}
					<img src="https://cdn.attendzen.io/{$event.accountRef}/lg_{$event.theme.virtual.bgImage.filename}" alt=""/>
				{:else}
					<img src="https://cdn.attendzen.io/{$event.accountRef}/xl_{$event.theme.virtual.bgImage.filename}" alt=""/>
				{/if}
			{:else if $event.theme.virtual.bgImage.unsplash}
				{#if bgBlur > 40}
					<img src="{$event.theme.virtual.bgImage.unsplash.url}&w=600" alt=""/>
				{:else if bgBlur > 20}
					<img src="{$event.theme.virtual.bgImage.unsplash.url}&w=1200" alt=""/>
				{:else}
					<img src="{$event.theme.virtual.bgImage.unsplash.url}&w=2400" alt=""/>
				{/if}
			{/if}
		{/if}
	</div>

	{#if !$ejected}
		{#if $attendee}
			{#if $syncClient}
				<Masthead/>
				<main class:leftDocked={$leftDocked} class:rightDocked={$rightDocked} class="room-{$room}" class:isPhone={$isPhone} class:hasInteractions={($room == 'auditorium') && stageInteractions}>
					{#if $room == 'auditorium'}
						<Auditorium bind:stageInteractions/>
					{:else if $room == 'exhibition'}
						<Exhibition/>
					{:else if $room == 'lounge'}
						<!-- not yet! -->
						<Lounge/>
					{:else if $room == 'studio'}
						<Studio/>
					{:else}
						<Lobby />
					{/if}
					{#if $showLeft && (!$event.setup.virtual.infoPanel || $event.setup.virtual.infoPanel.length) && ($room != 'studio')}
						<InfoPanel/>
					{/if}
					<Announcements/>
					<VideoCall/>
				</main>
			{/if}
		<!-- {:else if token}
			<Interstitial {firstname} on:submit={checkIn}/> -->
		{:else if checkinThreshold}
			<Hold {checkinThreshold} on:showtime={checkIn} />
		{:else}
			<Login/>
		{/if}
	{/if}

	<!-- <button id="tmp" on:click={announceSessionsInProgress}>#</button> -->

{/if}

{#if $busy}
	<!-- svelte-ignore a11y-click-events-have-key-events -->
	<div
		id="busy"
		in:fade|local={{ delay:$spinnerInDelay, duration:$spinnerInDuration }}
		out:fade|local={{ delay:$spinnerOutDelay, duration:$spinnerOutDuration }}
		on:click|stopPropagation
	>
		<Spinner size="100" speed="1200" color={$spinnerColor} thickness="1" gap="30"/>
	</div>
{/if}

{#if $bigError}
	<BigError/>
{/if}

<div id="breakout"></div>
<div id="dialog"></div>
<div id="measure" bind:clientHeight={vh}></div>

