<script>

	import { onMount, onDestroy, setContext, tick, createEventDispatcher } from 'svelte';
	import { writable } from 'svelte/store';

	import { Room, RoomEvent, VideoPresets, AudioPresets, DisconnectReason } from 'livekit-client';

	import { v4 as uuid } from 'uuid';

	import LeftPanel from './LeftPanel.svelte';
	import CenterPanel from './CenterPanel.svelte';
	import RightPanel from './RightPanel.svelte';
	import Settings from './Settings.svelte';

	import Overlay from "../ui/Overlay.svelte";
	import Dialog from "../ui/Dialog.svelte";
	import InactivityTimeout from "../ui/InactivityTimeout.svelte";

	import { event, session, syncClient, syncChannels, attendee, studioOptions, busy, epoch, currentSessions, backstage } from "../lib/stores.js";
	import { getLocalTracks, getLocalVideoTrack, getLocalAudioTrack, setTrackOptions, getMediaDevices } from '../lib/localTracks.js';
	import { getServerData, postServerData } from '../lib/prelude.js';

	const dispatch = createEventDispatcher();

	let sessionTimeoutId;

	let participants = writable([]);
	setContext('participants', participants);

	let layout = writable('gap');
	setContext('layout', layout);

	let banners = writable([]);
	setContext('banners', banners);

	let backgrounds = writable([]);
	setContext('backgrounds', backgrounds);

	let overlays = writable([]);
	setContext('overlays', overlays);

	let clips = writable([]);
	setContext('clips', clips);

	let clipTokens = writable({});
	setContext('clipTokens', clipTokens);

	let banner = writable(null);
	setContext('banner', banner);

	let background = writable(null);
	setContext('background', background);

	let overlay = writable(null);
	setContext('overlay', overlay);

	let clip = writable(null);
	setContext('clip', clip);

	let highlight = writable(null);
	setContext('highlight', highlight);

	let broadcasting = writable(false);
	setContext('broadcasting', broadcasting);

	let broadcastStarted = writable(false);
	setContext('broadcastStarted', broadcastStarted);	

	let livestreamStatus = writable('idle');
	setContext('livestreamStatus', livestreamStatus);

	let flipDuration = writable(0);
	setContext('flipDuration', flipDuration);

	let participantCount = writable(0);
	setContext('participantCount', participantCount);

	let room = writable(null);
	setContext('room', room);

	let feeds = writable([]);
	setContext('feeds', feeds);

	let localVideoTrack = writable(null);
	setContext('localVideoTrack', localVideoTrack);

	let localAudioTrack = writable(null);
	setContext('localAudioTrack', localAudioTrack);

	let host = (($attendee.type == 'login') || ($backstage.includes($session.ref))) ? true : false;
	setContext('host', host);

	let audienceViewMuted = writable(true);
	setContext('audienceViewMuted', audienceViewMuted);

	let rtcProvider = writable(null);
	setContext('rtcProvider', rtcProvider);

	let renderKey = writable(null);
	setContext('renderKey', renderKey);

	let mounted = false;

	let syncChannel;
	let studioChannel;
	let updateChannelFromStores = true;
	let attendeeNotifications;
	let eventNotifications;

	let channelDebouncer;

	let roomName;
	let roomToken;
	let providerLoaded = false;
	let processorsLoaded = false;

	let livekitHost;
	let livekitRoom;

	let videoDevice;
	let audioDevice;

	let nestedOverlay = false;

	onMount(async () => {

		$renderKey = $epoch;

		const studioData = await getServerData('virtual/studio/provider', {
			session: $session.ref
		});

		$rtcProvider = studioData.provider;

		if ($rtcProvider == 'livekit') {
			roomToken = studioData.roomToken;
			livekitHost = studioData.livekitHost;
			providerLoaded = true;
			processorsLoaded = true
		}

		try {
			attendeeNotifications = await $syncClient.list($syncChannels.attendee);
			attendeeNotifications.on('itemAdded', attendeeNotificationAdded);
		} catch (e) {
			console.log("Sync error", e, $syncChannels.attendee);
		}

		try {
			eventNotifications = await $syncClient.list($syncChannels.event);
			eventNotifications.on('itemAdded', eventNotificationAdded);
		} catch (e) {
			console.log("Sync error", e, $syncChannels.event);
		}

	});

	onDestroy(() => {
		if (attendeeNotifications) attendeeNotifications.close();
		if (eventNotifications) eventNotifications.close();
		closeRoom();
	});

	function attendeeNotificationAdded(message) {
		if (message && message.item && message.item.data && message.item.data.type) {
			if (message.item.data.sessionRef && (message.item.data.sessionRef == $session.ref)) {
				if (message.item.data.type == 'poll') {
					$session.pollResponses[message.item.data.poll] = message.item.data.response;
					$session = $session;
				}
			}
		}
	}
	
	function eventNotificationAdded(message) {
		const messageData = message.item.data;
		if (messageData && messageData.type && (messageData.type == 'livestream')) {
			if (messageData.stream && $session && (messageData.stream == $session.livestream) && messageData.status) {
				console.log('livestreamStatus', messageData.status);
				$livestreamStatus = messageData.status;
				if ((!$livestreamStatus || $livestreamStatus == 'idle')) {
					$broadcastStarted = false;
				}
			}
		}
	}

	async function setLocalTracks() {
		if ($rtcProvider == 'livekit') {
			if (!livekitRoom) {
				livekitRoom = createLivekitRoom();
				livekitRoom.prepareConnection('wss://' + livekitHost, roomToken);
				console.log('prepareConnection');
			}
		} else {
			[$localVideoTrack, $localAudioTrack] = await getLocalTracks($studioOptions.videoDevice, $studioOptions.audioDevice, $rtcProvider);
			console.log('setLocalTracks localVideoTrack', {$localVideoTrack});
		}
	}

	async function switchVideoDevice() {
		if ($rtcProvider == 'twilio') {
			if (mounted && (videoDevice != $studioOptions.videoDevice)) {
				videoDevice = $studioOptions.videoDevice;
				if ($localVideoTrack) $localVideoTrack.detach(); // same method in LiveKit and Twilio
				$localVideoTrack = await getLocalVideoTrack(videoDevice, $rtcProvider);
				if ($room) {
					let currentTracks = [];
					$room.localParticipant.videoTracks.forEach(publication => {
						if (publication.track.name != 'screen') {
							currentTracks.push(publication.track)
						}
					});
			      if (currentTracks.length) $room.localParticipant.unpublishTracks(currentTracks);
			      $room.localParticipant.publishTrack($localVideoTrack);
			   }
			}
		}
	}

	async function switchAudioDevice() {
		if ($rtcProvider == 'twilio') {
			// console.log('switchAudioDevice', audioDevice, $studioOptions.audioDevice);
			if (mounted && (audioDevice != $studioOptions.audioDevice)) {
				audioDevice = $studioOptions.audioDevice;
				if ($localAudioTrack) $localAudioTrack.detach(); // same method in LiveKit and Twilio
				$localAudioTrack = await getLocalAudioTrack(audioDevice, $rtcProvider);
				if ($room) {
					let currentTracks = [];
					$room.localParticipant.audioTracks.forEach(publication => {
						if (publication.track.name != 'screenAudio') {
							currentTracks.push(publication.track)
						}
					});
					// console.log({currentTracks});
			      if (currentTracks.length) $room.localParticipant.unpublishTracks(currentTracks);
			      $room.localParticipant.publishTrack($localAudioTrack);
			      // console.log('published', $localAudioTrack);
			   }
			}
		}
	}

	function createLivekitRoom() {
		return new Room({
			adaptiveStream: true,
			dynacast: true,
			disconnectOnPageLeave: true,
			publishDefaults: {
				simulcast: true,
				videoSimulcastLayers: [
					VideoPresets.h540,
					VideoPresets.h1080
				],
			},
			videoCaptureDefaults: {
    			resolution: VideoPresets.h1080.resolution,
  			}
		});
	}

	async function initialiseSession() {

		// $participants = [];

		$busy = true;

		if ($rtcProvider == 'twilio') {
			if ($localVideoTrack) $localVideoTrack.stop();
			if ($localAudioTrack) $localAudioTrack.stop();
			await setLocalTracks();
		}

		if (!roomName) {

			const sessionData = await getServerData('virtual/session', {
				session: $session.ref,
				studio: 1
			});

			if (sessionData) {

				$session.syncChannel = sessionData.syncChannel;
				$session.controlChannel = sessionData.controlChannel;
				$session.livestream = sessionData.livestream;
				$session.playback = sessionData.playback;
				$session.token = sessionData.token;

				if (sessionData.privateChannel) $session.privateChannel = sessionData.privateChannel;

				if (sessionData.studioChannel) {

					studioChannel = await $syncClient.document(sessionData.studioChannel);
					setStoresFromSync(studioChannel.data);

					// $broadcasting = null;

					studioChannel.on('updated', (args) => {
						setStoresFromSync(args.data);
					});

				}

				if ($session.syncChannel) {
					syncChannel = await $syncClient.list($session.syncChannel);
					syncChannel.on('itemAdded', function(i) {
						if (i.item.data.type == 'studio') {
							refreshLibrary(i.item.data.lib);
						}
					});
				}

				if (sessionData.room) {
					roomName = sessionData.room;
				}

				if (!roomToken && sessionData.roomToken) {
					roomToken = sessionData.roomToken;
				}

				if (!livekitHost && sessionData.livekitHost) {
					livekitHost = sessionData.livekitHost;
				}

				$backgrounds = sessionData.backgrounds;
				$banners = sessionData.banners;
				$overlays = sessionData.overlays;
				$clips = sessionData.clips;

				$livestreamStatus = sessionData.livestreamStatus;

				console.log('session init: livestreamStatus', $livestreamStatus);

				if ((!$livestreamStatus || $livestreamStatus == 'idle')) {
					$broadcastStarted = false;
				}

				getClipTokens(true);

				$session.pollResponses = sessionData.pollResponses ? sessionData.pollResponses : {};

			}

		}

		if (!$room) {

			if ($rtcProvider == 'livekit') {

				if (!livekitRoom) {
					livekitRoom = createLivekitRoom();
				}

				console.log('livekit room created');

				$room = livekitRoom;

				await $room.connect('wss://' + livekitHost, roomToken, {
					autoSubscribe: true
				});

				const roomSid = await $room.getSid();

				console.log('connected to room', $room.name, roomSid, $attendee.ref);

				if ($room) {

					$room.on(RoomEvent.LocalTrackPublished, addToParticipants);

					if ($localVideoTrack) {
						console.log('publishing local video track');
						await $room.localParticipant.publishTrack($localVideoTrack);
					}

					if ($localAudioTrack) {
						console.log('publishing local audio track');
						await $room.localParticipant.publishTrack($localAudioTrack, {
							audioPreset: AudioPresets.speech
						});
					}

					// $room.on(RoomEvent.Connected, (e) => {
					// 	console.log('RoomEvent.Connected', e);
					// });

					// $room.on(RoomEvent.ConnectionStateChanged, (e) => {
					// 	console.log('RoomEvent.ConnectionStateChanged', e);
					// });

					$room.on(RoomEvent.Disconnected, (r) => {
						// cf. https://docs.livekit.io/client-sdk-js/enums/DisconnectReason.html
						const reason = DisconnectReason[r];
						console.log('RoomEvent.Disconnected', reason);
						if (reason != 'CLIENT_INITIATED') {
							exitSession();
						}
					});

					// $room.on(RoomEvent.ConnectionStateChanged, (e) => {
					// 	console.log('RoomEvent.ConnectionStateChanged', e);
					// });

					$room.on(RoomEvent.Reconnecting, (e) => {
						console.log('RoomEvent.Reconnecting', e);
						// If you close your laptop, no events fire -- not even Disconnect
						// but when you re-open, you'll reconnect
						// We actually want you to go through the greenroom again though
						exitSession();
					});

					// $room.on(RoomEvent.Reconnected, (e) => {
					// 	console.log('RoomEvent.Reconnected', e);
					// });

					// $room.on(RoomEvent.ParticipantConnected, () => {
					// 	cleanupParticipants();
					// });

					$room.on(RoomEvent.ParticipantDisconnected, (e) => {
						console.log('RoomEvent.ParticipantDisconnected', e);
						cleanupParticipants('RoomEvent.ParticipantDisconnected');
					});

					$room.on(RoomEvent.TrackSubscriptionFailed, (e) => {
						console.log('RoomEvent.TrackSubscriptionFailed', e);
						cleanupParticipants('RoomEvent.TrackSubscriptionFailed');
					});

					$room.on(RoomEvent.TrackSubscribed, (e) => {
						console.log('RoomEvent.TrackSubscribed', e);
						cleanupParticipants('RoomEvent.TrackSubscribed');
					});

					$room.on(RoomEvent.TrackUnpublished, (e) => {
						console.log('RoomEvent.TrackUnpublished', e);
						cleanupParticipants('RoomEvent.TrackUnpublished');
					});

					$room.on(RoomEvent.LocalTrackPublished, (e) => {
						console.log('RoomEvent.LocalTrackPublished', e);
						cleanupParticipants('RoomEvent.LocalTrackPublished');
					});

					$room.on(RoomEvent.LocalTrackUnpublished, (e) => {
						console.log('RoomEvent.LocalTrackUnpublished', e);
						cleanupParticipants('RoomEvent.LocalTrackUnpublished');
					});

					$feeds = []; // vestigial

					// $room.on(RoomEvent.DataReceived, (payload, participant, kind) => {
					//    const decoder = new TextDecoder();
					// 	const strData = decoder.decode(payload);
					// 	console.log(strData);
					// });

				}

			} else {

				let roomConfig = {
					name: roomName,
					preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }],
				};

				if ($localVideoTrack || $localAudioTrack) {
					roomConfig.tracks = [];
				}

				if ($localVideoTrack) {
					roomConfig.tracks.push($localVideoTrack);
				} else {
					roomConfig.video = false;
				}

				if ($localAudioTrack) {
					roomConfig.tracks.push($localAudioTrack);
				} else {
					roomConfig.audio = false;
				}

				try {
					$room = await Twilio.Video.connect(roomToken, roomConfig);
				} catch (err) {
					console.log('Twilio room error', roomToken, roomConfig, {err});
				}

				if ($room) {

					$feeds = Array.from($room.participants.values());

					$room.on("participantConnected", feed => {
						console.log('participantConnected', feed);
						// $feeds = [...$feeds, feed];
						$feeds = Array.from($room.participants.values());
						cleanupParticipants('twilio participantConnected');
					});

					$room.on("participantDisconnected", feed => {
						console.log('participantDisconnected', feed);
						// $feeds = $feeds.filter(p => p !== feed);
						$feeds = Array.from($room.participants.values());
						cleanupParticipants('twilio participantDisconnected');
					});

				}

			}

		}

		cleanupParticipants('initialiseSession');

		sessionTimeoutId = uuid();

		console.log('session mounted...');
		mounted = true;

		$busy = false;

		if ($rtcProvider == 'twilio') {
			addToParticipants();
		}

	}

	async function addToParticipants(track) {

		// If I'm not already in the participant list, add me.
		
		await tick();

		let participantId = $attendee.ref;
		let screenShare = false;

		if (track) {
			if ((track.source == 'screen_share') || (track.source == 'screen_share_audio')) {
				participantId = 's_' + $attendee.ref;
				screenShare = true;
			}
		}

		let found = false;

		for (const p of $participants) {
			if (p.id == participantId) {
				found = true;
				break;
			}
		}

		if (found) {
			setParticipantOptions();
		} else {
			$participants.push({
				id: participantId,
				t: track ? track.trackSid : '',
				o: false,
				s: false,
				m: false,
				f: screenShare ? false : $studioOptions.mirrorCamera,
				b: screenShare ? false : $studioOptions.blurBackground
			});
			$participants = $participants;
			console.log('adding attendee to participants...', participantId, $participants);
		}

	}

	async function closeRoom() {
		console.log('closing room...');
		if ($localVideoTrack) await $localVideoTrack.stop();
		if ($localAudioTrack) await $localAudioTrack.stop();
		if ($room) {
			if ($rtcProvider == 'livekit') {
				$room.disconnect();
				$room = null;
			} else {
				$room.localParticipant.videoTracks.forEach(publication => {
					publication.unpublish();
				});
				$room.localParticipant.audioTracks.forEach(publication => {
					publication.unpublish();
				});
				$room.removeAllListeners();
				$room.disconnect();
				$room = null;
			}
		}
		let filtered = $participants.filter((p) => {
			if ((p.id == 's_' + $attendee.ref) || (p.id == $attendee.ref)) {
				return false;
			} else {
				return true;
			}
		});
		$participants = filtered;
		updateChannel();
		console.log('closeRoom complete');
	}

	async function restoreSession() {
		await setLocalTracks();
		mounted = false;
	}

	let libraryDebouncers = {};

	function refreshLibrary(kind) {
		clearTimeout(libraryDebouncers[kind]);
		libraryDebouncers[kind] = setTimeout(async () => {
			if ($session) {
				const newData = await getServerData('virtual/studio/' + kind, {
					sessionRef: $session.ref
				});
				if (kind == 'backgrounds') {
					$backgrounds = newData;
				} else if (kind == 'overlays') {
					$overlays = newData;
				} else if (kind == 'banners') {
					$banners = newData;
				} else if (kind == 'clips') {
					$clips = newData;
				}
			}
		}, 100);
	}

	let cleanupDebouncer;

   function cleanupParticipants(caller) {
   	clearTimeout(cleanupDebouncer);
   	cleanupDebouncer = setTimeout(() => {
   		console.log('cleanupParticipants', caller);
   		let remove = [];
	   	if ($participants && $room) {
				for (const [i,p] of $participants.entries()) {
					if ($rtcProvider == 'livekit') {
						let found = false;
						let attendeeId = p.id;
						let sid = '';
						let local = false;
						// console.log('cleanupParticipants', attendeeId, $attendee.ref);
						if (p.id.startsWith('s_')) attendeeId = p.id.substring(2);
						if (attendeeId == $attendee.ref) {
							// local participant: check screenshares and set SID
							local = true;
							if ($room) {
								if (p.id.startsWith('s_')) {
									let pub = $room.localParticipant.getTrackPublication('screen_share');
									console.log('found screen_share', pub);
									if (pub) {
										found = true;
										sid = pub.trackSid;
									}
								} else {
									if (!p.t) found = true; // allow for a participant that doesn't have a camera
									let pub = $room.localParticipant.getTrackPublication('camera');
									console.log('found camera', pub);
									if (pub) {
										found = true;
										sid = pub.trackSid;
									}
								}
							}
						} else {
							if ($room && $room.remoteParticipants) {
								let fds = Array.from($room.remoteParticipants.values());
								for (const f of fds) {
									if (attendeeId == f.identity) {
										found = true;
										break;
									}
								}
							}
						}
						if (found) {
							if (local) {
								if ($participants[i] && ($participants[i].t != sid)) {
									console.log('cleanupParticipants -- changing sid', p.id, $participants[i].t, sid);
									$participants[i].t = sid;
								}
							}
						} else {
							console.log('cleanupParticipants -- participant not found', p.id);
							remove.push(p.id);
						}
					} else {
						if (p.id.startsWith('s_')) {
							let fds = Array.from($room.participants.values());
							fds.push($room.localParticipant);
							let foundTrack = false;
							const pa = p.id.substring(2);
							for (const f of fds) {
								if (pa == f.identity) {
									for (const t of f.tracks) {
										if (t[1].trackName == 'screen') {
											foundTrack = true;
											break;
										}
									}
									break;
								}
							}
							if (!foundTrack) {
								remove.push(p.id);
							}
						}
					}
				}
			}
			if (remove.length) {
				console.log('cleanupParticipants -- filtering', remove);
				// let filtered = $participants.filter(f => !remove.includes(f.id));
				// Is the above causing trouble sometimes? Let's try being more explicit...
				// Could the issue be where multiple people run this at the same time?
				// Should we try moving this to the server -- so ONLY THE API changes $paticipants ..?
				let filtered = [];
				for (const f of $participants) {
					if (!remove.includes(f.id)) {
						filtered.push(f);
					}
				}
				// $participants = filtered;
			}
		}, 400);
   }

	async function setStoresFromSync(syncData) {
		// console.log('setStoresFromSync', syncData);
		updateChannelFromStores = false;
		$participants = syncData.p ? syncData.p : [];
		$layout = syncData.l ? syncData.l : 'gap';
		$background = syncData.b ? syncData.b : null;
		$overlay = syncData.o ? syncData.o : null;
		$clip = syncData.c ? syncData.c : null;
		$banner = syncData.x ? syncData.x : null;
		$highlight = syncData.h ? syncData.h : null;
		$broadcasting = syncData.g ? syncData.g : false;
		$broadcastStarted = syncData.z ? syncData.z : false;
		await tick();
		updateChannelFromStores = true;
	}

	function updateChannel() {
		if (mounted && studioChannel && updateChannelFromStores) {
			let syncData = {};
			syncData.p = $participants;
			syncData.l = $layout;
			syncData.b = $background;
			syncData.o = $overlay;
			syncData.c = $clip;
			syncData.x = $banner;
			syncData.h = $highlight;
			syncData.g = $broadcasting;
			syncData.z = $broadcastStarted;
			studioChannel.set(syncData);
		}
	}

	function countParticipants() {
		let c = 0;
		for (const p of $participants) {
			if (p.o) {
				c++;
			}
		}
		$participantCount = c;
	}

	function inactivityTimeout() {
		// console.log('inactivityTimeout');
		// Skip if the session is being recorded or is happening now (but reset the timer)
		let skipTimeout = false;
		if ($broadcasting) {
			skipTimeout = true;
		} else if ($currentSessions) {
			for (const s of $currentSessions) {
				if (s.ref == $session.ref) {
					skipTimeout = true;
					break;
				}
			}
		}
		if (skipTimeout) {
			sessionTimeoutId = uuid();
		} else {
			let filtered = $participants.filter((p) => {
				if ((p.id == 's_' + $attendee.ref) || (p.id == $attendee.ref)) {
					return false;
				} else {
					return true;
				}
			});
			$participants = filtered;
			closeRoom();
		}
	}

	function setParticipantOptions() {
		let updated = false;
		for (const [i,p] of $participants.entries()) {
			if (p.id == $attendee.ref) {
				if (p.f != $studioOptions.mirrorCamera) {
					$participants[i].f = $studioOptions.mirrorCamera;
					updated = true;
				}
			}
		}
		if (updated) $participants = $participants;
	}

	async function getClipTokens(force) {
		if (force || mounted) {
			if (($event.setup.virtual.studio && $event.setup.virtual.studio.clips && $event.setup.virtual.studio.clips.length) || ($clips && $clips.length)) {
				const tokenData = await getServerData('virtual/studio/cliptokens', {
					sessionRef: $session.ref
				});
				$clipTokens = tokenData.clipTokens;
			}
		}
	}

	function logBroadcasting() {
		postServerData('virtual/studio/broadcasting', {
			livestream: $session.livestream
		});
	}

	function exitSession() {
		dispatch('exit');
	}

	function triggerSetTrackOptions() {
		setTrackOptions($localVideoTrack, $localAudioTrack, $studioOptions, $rtcProvider, $room);
	}
	
	$: if (providerLoaded && processorsLoaded) {
		setLocalTracks();
	}

	$: countParticipants($participants);
	$: updateChannel($participants, $layout, $background, $overlay, $clip, $banner, $highlight, $broadcasting);

	$: switchVideoDevice($studioOptions.videoDevice);
	$: switchAudioDevice($studioOptions.audioDevice);

	$: triggerSetTrackOptions($localVideoTrack, $localAudioTrack, $studioOptions, $rtcProvider);
	$: setParticipantOptions($studioOptions);

	$: if ($broadcasting) {
		logBroadcasting();
	}

	$: console.log('broadcastStarted', $broadcastStarted);

	$: if ($clips || $event) {
		getClipTokens();
	}

	// $: if ($renderKey) {
	// 	cleanupParticipants();
	// 	console.log({$participants});
	// }

</script>

<style>
</style>

<svelte:head>
	{#if $rtcProvider == 'twilio'}
		<script src="https://sdk.twilio.com/js/video/releases/2.27.0/twilio-video.min.js" on:load={() => { providerLoaded = true }}></script>
		<script src="/twilio/videoProcessors/2.0.0/twilio-video-processors.min.js" on:load={() => { processorsLoaded = true }}></script>
	{/if}
</svelte:head>

{#if $session}
	{#if mounted && $room}
		<CenterPanel on:exit/>
		<LeftPanel/>
		<RightPanel/>
		{#if sessionTimeoutId}
			{#key sessionTimeoutId}
				<InactivityTimeout minutes={90} on:timeout={inactivityTimeout} />
			{/key}
		{/if}
	{:else if providerLoaded && processorsLoaded}
		{#if mounted}
			<Dialog
				text="We’ve disconnected you from this studio session due to inactivity."
				actions={[
					{
						label: "Reconnect",
						dispatch: "confirm"
					},
					{
						label: "Exit",
						ghost: true,
						dispatch: "escape"
					}
				]}
				on:escape={exitSession}
				on:confirm={restoreSession}
			/>
		{:else}
			<Overlay modal={true} wide={true} nested={nestedOverlay} on:escape={exitSession}>
				<Settings greenRoom={true} on:save={initialiseSession} bind:nestedOverlay/>
			</Overlay>
		{/if}
	{/if}
{/if}