<script>

	import { onMount, onDestroy, createEventDispatcher, getContext } from 'svelte';
	import { writable } from 'svelte/store';
	import { fade, slide } from 'svelte/transition';

	import Portal from "svelte-portal";

	import Button from "../ui/Button.svelte";
	import Switch from "../ui/fields/Switch.svelte";
	import Field from "../ui/Field.svelte";
	import Avatar from "../ui/Avatar.svelte";

	import Overlay from "../ui/Overlay.svelte";
	import Profile from "../Masthead/Avatar/Profile.svelte";

	import { studioOptions, attendees, attendee } from "../lib/stores.js";
	import { getLocalTracks, getLocalVideoTrack, getLocalAudioTrack, setTrackOptions, getMediaDevices, blurIsSupported } from '../lib/localTracks.js';
	import { pollAudioLevel } from '../lib/pollAudioLevel.js';

	const dispatch = createEventDispatcher();

	const rtcProvider = getContext('rtcProvider');
	const localVideoTrack = getContext('localVideoTrack');
	const localAudioTrack = getContext('localAudioTrack');
	const room = getContext('room');

	export let greenRoom = false;
	export let nestedOverlay = false;

	let audioPollActive = writable(true);

	let workingLocalVideoTrack;
	let workingLocalAudioTrack;

	let videoDevices = [];
	let audioDevices = [];

	let videoDevice;
	let audioDevice;

	let videoElement;

	let workingData = {};
	let mounted = false;
	let vol = 0;
	let profileOverlay = false;
	let ready = false;

	let optionsDebouncer;

	let err;

	onMount(async () => {

		workingData = JSON.parse(JSON.stringify($studioOptions));

		mounted = true;

		navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then(function (stream) {

			updateDeviceList(stream);
			navigator.mediaDevices.addEventListener("devicechange", updateDeviceList);

		}).catch(async function(e) {

			if (e.name == "NotReadableError" || e.name == "TrackStartError") {
				err = 'Your camera or microphone are already in use by another app.';
			} else if (e.name == "NotAllowedError" || e.name == "PermissionDeniedError") {
				err = 'You have denied permission for this browser to access your camera and microphone.';
			}

			if (!err) {
				await updateDeviceList();
				navigator.mediaDevices.addEventListener("devicechange", updateDeviceList);
			}

			ready = true;

		});

	});

	onDestroy(() => {
		navigator.mediaDevices.removeEventListener("devicechange", updateDeviceList);
		$audioPollActive = false;
		// if (localVideoTrack) localVideoTrack.stop();
		// if (localAudioTrack) localAudioTrack.stop();
	});

	async function updateDeviceList(stream) {

		[videoDevices, audioDevices] = await getMediaDevices();

		// console.log({audioDevices});

		// 21 Sep 2023 -- workaround for Firefox
		// cf. https://stackoverflow.com/questions/46648645/navigator-mediadevices-enumeratedevices-not-display-device-label-on-firefox
		// 24 Jan 2025 -- still needed?
		// if (stream) {
		// 	stream.getTracks().forEach(function(track) {
		// 		track.stop();
		// 	});
		// }

		// if (greenRoom) videoDevices = [];

		if (videoDevices.length) {

			for (const v of videoDevices) {
				if (workingData.videoDevice && (v.value == workingData.videoDevice)) {
					videoDevice = v.value;
					break;
				}
			}

			if (!videoDevice) {
				videoDevice = videoDevices[0] ? videoDevices[0].value : null;
				workingData.videoDevice = videoDevice;
			}

		} else {

			err = 'We can’t find your camera. Possibly another app on your device is overriding this, or you don’t have one connected?';
			// const mediaDevices = await navigator.mediaDevices.enumerateDevices();
			// err = 'Devices JSON (v): ' + JSON.stringify(mediaDevices);

			workingData.videoDevice = null;

		}

		if (audioDevices.length) {

			for (const a of audioDevices) {
				if (workingData.audioDevice && (a.value == workingData.audioDevice)) {
					audioDevice = a.value;
					break;
				}
			}

			if (!audioDevice) {
				audioDevice = audioDevices[0] ? audioDevices[0].value : null;
				workingData.audioDevice = audioDevice;
			}

		} else {

			err = 'We can’t find your microphone. Possibly another app on your device is overriding this, or you don’t have one connected?';
			// const mediaDevices = await navigator.mediaDevices.enumerateDevices();
			// err = 'Devices JSON (a): ' + JSON.stringify(mediaDevices);

			workingData.audioDevice = null;

		}

		if (!videoDevices.length && !audioDevices.length) {
			err = 'We can’t find your camera or microphone. Possibly another app on your device is overriding this, or you don’t have any connected?';
			// const mediaDevices = await navigator.mediaDevices.enumerateDevices();
			// err = 'Devices JSON (c): ' + JSON.stringify(mediaDevices);

			workingData.videoDevice = null;
			workingData.audioDevice = null;

		}

		if (greenRoom) {
			setTimeout(() => {
				ready = true;
			}, 2000);
		} else {
			ready = true;
		}

		if (greenRoom) {

			[workingLocalVideoTrack, workingLocalAudioTrack] = await getLocalTracks(videoDevice, audioDevice, $rtcProvider);

			if (!err) {
				// God knows what the error is, TBH.
				// We have permission and device IDs.
				// The app override thing is a stab in the dark here.
				if (!workingLocalVideoTrack && !workingLocalAudioTrack) {
					err = 'We can’t access your camera or microphone. Possibly another app on your device is overriding this?';
					// const mediaDevices = await navigator.mediaDevices.enumerateDevices();
					// err = 'Devices JSON (g): ' + JSON.stringify(mediaDevices);
				} else if (!workingLocalVideoTrack) {
					err = 'We can’t access your camera. Possibly another app on your device is overriding this?';
				} else if (!workingLocalAudioTrack) {
					err = 'We can’t access your microphone. Possibly another app on your device is overriding this?';
				}
			}

		}

	}

	function handleVideo() {
		if (greenRoom) {
			workingLocalVideoTrack.attach(videoElement); // same method in LiveKit and Twilio
		}
	}

	function handleAudio() {
		if (greenRoom) {
			pollAudioLevel(workingLocalAudioTrack, audioPollActive, level => {
				vol = level;
				// console.log({vol});
			});
		}
	}

	async function saveSettings() {
		console.log('saveSettings');
		$audioPollActive = false;
		if (workingLocalVideoTrack) $localVideoTrack = workingLocalVideoTrack;
		if (workingLocalAudioTrack) $localAudioTrack = workingLocalAudioTrack;
		$room = $room;
		$studioOptions = JSON.parse(JSON.stringify(workingData));
		dispatch('save');
	}

	function openProfile() {
		profileOverlay = true;
	}

	async function switchVideoDevice() {
		if (mounted && (videoDevice != workingData.videoDevice)) {
			if ($rtcProvider == 'livekit') {
				clearTimeout(optionsDebouncer);
				optionsDebouncer = setTimeout(() => {
					workingData.videoDevice = videoDevice;
					setTrackOptions(workingLocalVideoTrack, workingLocalAudioTrack, workingData, $rtcProvider, $room);
				}, 200);
			} else {
				workingData.videoDevice = videoDevice;
				if (workingLocalVideoTrack) workingLocalVideoTrack.detach();
				if (greenRoom) workingLocalVideoTrack = await getLocalVideoTrack(videoDevice, $rtcProvider);
			}
		}
	}

	async function switchAudioDevice() {
		if (mounted && (audioDevice != workingData.audioDevice)) {
			if ($rtcProvider == 'livekit') {
				clearTimeout(optionsDebouncer);
				optionsDebouncer = setTimeout(() => {
					workingData.audioDevice = audioDevice;
					setTrackOptions(workingLocalVideoTrack, workingLocalAudioTrack, workingData, $rtcProvider, $room);
				}, 200);
				handleAudio();
			} else {
				workingData.audioDevice = audioDevice;
				if (workingLocalAudioTrack) workingLocalAudioTrack.detach();
				if (greenRoom) workingLocalAudioTrack = await getLocalAudioTrack(audioDevice, $rtcProvider);
			}
		}
	}

	function setTrackOpts() {
		clearTimeout(optionsDebouncer);
		optionsDebouncer = setTimeout(() => {
			setTrackOptions(workingLocalVideoTrack, workingLocalAudioTrack, workingData, $rtcProvider, $room);
		}, 200);
	}

	$: if (workingLocalVideoTrack) handleVideo();
	$: if (workingLocalAudioTrack) handleAudio();

	$: switchVideoDevice(videoDevice);
	$: switchAudioDevice(audioDevice);

	$: setTrackOpts(workingLocalVideoTrack, workingLocalAudioTrack, workingData);

	$: nestedOverlay = profileOverlay;

</script>

<style>
	.video {
		position: relative;
/*		max-width: 100%;*/
		width: 100%;
		border-radius: 4px;
		aspect-ratio: 16 / 9;
		overflow: hidden;
		background: var(--blend-80);
	}
	.video video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 20;
		object-fit: cover;
	}

	.video video.blur {
		top: -2%;
		left: -2%;
		width: 104%;
		height: 104%;
	}

	.video video.mirror {
		transform: scale(-1,1);
	}

	.video :global(.avatar) {
		z-index: 10;
		top: 50%;
		left: 50%;
		width: 3rem;
		height: 3rem;
		transform: translate(-50%,-50%);
	}
	.video :global(abbr) {
		font-size: 1.4rem;
	}

	.cols {
		display: flex;
		flex-wrap: wrap;
		gap: 1rem;
	}
	.cols > div {
		display: flex;
		flex: 0 1 auto;
		width: calc(50% - 0.5rem);
	}
	@media (max-width: 500px) {
		.cols > div {
			flex: 1 0 auto;
		}
		.cols > div.narrow {
			flex: 0 1 auto;
		}
	}
	.cols.preview {
		padding: 0.5rem 0 1rem 0;
	}
	.controls {
		padding: 0 0 0.5rem 0;
		flex-direction: column;
		gap: 0.6rem;
	}
	.meter {
		border-radius: 4px;
		width: min-content;
		padding: 1rem;
		background: var(--panelColor);
		height: 100%;
		box-sizing: border-box;
		color: var(--textColor);
		display: flex;
		flex-direction: column;
		gap: 1rem;
		justify-content: center;
	}
	.meter svg {
		width: 1rem;
		transform: scale(1.6);
	}
	.meter > div {
		display: flex;
		flex-direction: column;
		gap: 0.2rem;
	}
	.meter > div > span {
		display: block;
		width: 1rem;
		height: 0.4rem;
		background: var(--blend-10);
	}
	.meter.vol1 span:nth-child(8) ~ span,
	.meter.vol2 span:nth-child(7) ~ span,
	.meter.vol3 span:nth-child(6) ~ span,
	.meter.vol4 span:nth-child(5) ~ span,
	.meter.vol5 span:nth-child(4) ~ span,
	.meter.vol6 span:nth-child(3) ~ span,
	.meter.vol7 span:nth-child(2) ~ span,
	.meter.vol8 span:nth-child(1) ~ span,
	.meter.vol9 span {
		background: var(--accentColor);
	}

	.controls :global(.field) {
		margin: 0.5rem 0;
	}

	.switch {
		height: 1.4rem;
		display: flex;
		gap: 0.5rem;
		align-items: center;
		font-size: 0.6875rem;
	}
	.switch :global(.toggle-group) {
		transform: translateY(-50%);
	}

	.controls :global(.select button span) {
		max-width: 100%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.bordered {
		margin: 0.5rem 0 0 0;
		padding: 1rem 0 0.5rem 0;
		border-top: 2px solid var(--panelColor);
	}
	.right {
		justify-content: flex-end;
	}
	.overlay-actions :global(.wrap) {
		flex: 0 0 calc(50% - 0.5rem);
	}

	.overlay-main {
		position: relative;
	}

	.err {
		padding: 0.5rem 0 1rem 0;
	}

	.err + div.cols {
		padding: 1rem 0 0.5rem 0;
		border-top: 2px solid var(--panelColor);
	}

	.err p {
		position: relative;
		padding-left: 1.8rem;
		line-height: 1.2;
		text-align: left;
		font-size: 0.8rem;
		line-height: 1.3;
		font-weight: 600;
		word-wrap: break-word; /* tmp */
	}

	.err p {
		color: var(--red);
	}

	.err svg {
		position: absolute;
		top: -0.05rem;
		left: 0;
		width: 1.1rem;
	}

	.controls.disabled {
		opacity: 0.4;
	}

	.profile {
		display: flex;
		gap: 0.5rem;
		align-items: center;
		border: 0;
		padding: 0;
		background: transparent;
		color: var(--textColor);
		font-weight: 600;
		cursor: pointer;
	}

	.profile :global(.avatar) {
		position: relative;
		top: 0;
		left: 0;
		pointer-events: none;
	}

</style>

<div class="overlay-title">
	{#if greenRoom}
		<h2>Let’s check your camera and microphone …</h2>
	{:else}
		<h2>Settings</h2>
	{/if}
</div>

<div class="overlay-main">

	{#if err}
		<div class="err" transition:slide|local={{ duration: 200 }}>
			<p>
				<svg viewBox="0 0 56 56"><path d="M3.73 52.65c-1.25-.03-2.45-.69-3.12-1.85-.67-1.16-.64-2.53-.04-3.63l24.26-42.04c.65-1.07 1.83-1.78 3.17-1.78 1.34 0 2.52.71 3.17 1.78l24.27 42.04c.6 1.1.63 2.47-.04 3.63-.67 1.16-1.87 1.82-3.12 1.85h-48.55zm24.27-43.08l-22.06 38.21h44.12l-22.06-38.21zM28 40.31c1.48 0 2.7 1.22 2.7 2.7s-1.22 2.7-2.7 2.7-2.7-1.22-2.7-2.7 1.22-2.7 2.7-2.7zm-2.33-18.91c0-1.54.9-2.49 2.33-2.49s2.33.95 2.33 2.49v14.43c0 1.54-.9 2.49-2.33 2.49s-2.33-.95-2.33-2.49v-14.43z"/></svg>
				<span>{err}</span>
			</p>
		</div>
	{/if}

	{#if mounted}

		{#if greenRoom}
			<div class="cols preview">
				<div>
					<div class="video">
						<!-- svelte-ignore a11y-media-has-caption -->
						<video bind:this={videoElement} class:mirror={workingData.mirrorCamera} class:blur={workingData.blurBackground} />
						<Avatar identity={$attendees[$attendee.ref]} hasMenu={false} />
					</div>
				</div>
				<div class="narrow">
					<div class="meter vol{vol}">
						<svg viewBox="0 0 48 48">
							<path d="M24 28.8c3.5 0 6.4-2.9 6.4-6.4v-8.4c0-3.5-2.9-6.4-6.4-6.4-3.5 0-6.4 2.9-6.4 6.4v8.5c0 3.5 2.9 6.3 6.4 6.3zm4.4-6.3c0 2.4-2 4.4-4.4 4.4-2.4 0-4.4-2-4.4-4.4v-8.5c0-2.4 2-4.4 4.4-4.4 2.2 0 4 1.6 4.3 3.7l.1 9.2zM32 20.6v3.2c0 3.9-3.1 7-7 7h-2c-3.9 0-7-3.1-7-7v-3.2h-2v3.2c0 4.9 4 9 9 9v5.6h-2.6v2h7.2v-2h-2.6v-5.6c4.9 0 9-4 9-9v-3.2h-2z"/>
						</svg>
						<div>
							<span></span>
							<span></span>
							<span></span>
							<span></span>
							<span></span>
							<span></span>
							<span></span>
							<span></span>
							<span></span>
						</div>
					</div>
				</div>
			</div>
		{/if}

		<div class="cols">
			<div class="controls" class:disabled={!videoDevices.length}>
				<Field
					ref="videoDevice"
					bind:value={videoDevice}
					component="SelectList"
					label="Camera"
					options="{videoDevices}"
					showNull={false}
					disabled={!videoDevices.length}
				/>
				<p class="switch">
					<Switch
						ref="mirrorCamera"
						bind:value={workingData.mirrorCamera}
						disabled={!videoDevices.length}
					/>
					<label for="mirrorCamera">Mirror camera</label>
				</p>
				{#if (($rtcProvider == 'twilio') || blurIsSupported())}
					<p class="switch">
						<Switch
							ref="blurBackground"
							bind:value={workingData.blurBackground}
							disabled={!videoDevices.length}
						/>
						<label for="blurBackground">Blur background</label>
					</p>
				{/if}
			</div>
			<div class="controls" class:disabled={!audioDevices.length}>
				<Field
					ref="audioDevice"
					bind:value={audioDevice}
					component="SelectList"
					label="Microphone"
					options="{audioDevices}"
					showNull={false}
					disabled={!audioDevices.length}
				/>
				{#if $rtcProvider == 'twilio'}
					<p class="switch">
						<Switch
							ref="noiseCancellation"
							bind:value={workingData.noiseCancellation}
							disabled={!audioDevices.length}
						/>
						<label for="noiseCancellation">Reduce background noise</label>
					</p>
				{/if}
			</div>
		</div>

		<!-- <div class="cols bordered">
			<div>	
				<p class="switch">
					<Switch ref="showName" bind:value={workingData.showName}/>
					<label for="showName">Show name</label>
				</p>
			</div>
			<div class="right">
				<Button label="Edit profile" ghost={true} grow={false} mini={true} on:click={openProfile} />
			</div>
		</div> -->

		{#if $attendees && $attendee && $attendees[$attendee.ref]}
			<div class="bordered">
				<button type="button" class="profile" on:click={openProfile}>
					<Avatar identity={$attendees[$attendee.ref]} hasMenu={false} />
					{$attendees[$attendee.ref].f} {$attendees[$attendee.ref].l}
				</button>
			</div>
		{/if}

	{/if}

	<!-- {#if !mounted && !err}
		<div in:fade={{ delay: 1000, duration: 600 }} out:fade|local={{ duration: 200 }}>
			<p class="slate">Please allow camera and microphone access</p>
		</div>
	{/if} -->

</div>

<div class="overlay-actions">
	{#if greenRoom}
		<Button label="Join studio" on:click={saveSettings} wide={false} grow={false} disabled={!ready} />
	{:else}
		<Button label="Save" on:click={saveSettings} wide={false} grow={false} disabled={!ready} />
	{/if}
	<!-- <Button label="Exit" ghost={true} on:click={exitOverlay} /> -->
</div>

{#if profileOverlay}
	<Portal target="#breakout">
		<Overlay on:escape={() => { profileOverlay = false }}>
			<Profile on:saved={() => { profileOverlay = false }}/>
		</Overlay>
	</Portal>
{/if}