import { createLocalTracks, createLocalVideoTrack, createLocalAudioTrack, VideoPresets } from 'livekit-client';
import { ProcessorWrapper, BackgroundTransformer, BackgroundBlur } from '@livekit/track-processors';

export async function getLocalTracks(videoDevice, audioDevice, rtcProvider) {
	if (rtcProvider == 'livekit') {
		let [localAudioTrack,localVideoTrack] = await createLocalTracks({
			audio: {
				deviceId: audioDevice,
			},
			video: {
				deviceId: videoDevice,
				resolution: VideoPresets.h720.resolution,
			}
		});
		return [localVideoTrack, localAudioTrack];
	} else {
		let localVideoTrack = await getLocalVideoTrack(videoDevice, rtcProvider);
		let localAudioTrack = await getLocalAudioTrack(audioDevice, rtcProvider);
		return [localVideoTrack, localAudioTrack];
	}
}

export async function getLocalVideoTrack(videoDevice, rtcProvider) {

	if (!videoDevice) return;

	if (rtcProvider == 'livekit') {

		let localVideoTrack = await createLocalVideoTrack({
			deviceId: videoDevice,
			resolution: VideoPresets.h720.resolution,
		});

		// console.log('created localVideoTrack', {localVideoTrack});

		return localVideoTrack;

	} else {

		const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

		const captureConfig = {
			deviceId: { exact: videoDevice },
			width: isSafari ? 640 : 1280,
			height: isSafari ? 480 : 720,
			frameRate: 24,
		};

		let localVideoTrack = await Twilio.Video.createLocalVideoTrack(captureConfig);

		localVideoTrack.enable();

		return localVideoTrack;

	}

}

export async function getLocalAudioTrack(audioDevice, rtcProvider) {

	if (!audioDevice) return;

	if (rtcProvider == 'livekit') {

		let localAudioTrack = await createLocalAudioTrack({
			deviceId: audioDevice,
		});

		// console.log('created localAudioTrack', {localAudioTrack});

		return localAudioTrack;

	} else {

		let localAudioTrack = await Twilio.Video.createLocalAudioTrack({
			deviceId: { exact: audioDevice },
			noiseCancellationOptions: {
				sdkAssetsPath: '/twilio/krisp/1.0.0/',
				vendor: 'krisp'
			}
		});

		localAudioTrack.enable();

		return localAudioTrack;

	}

}

export function blurIsSupported() {
	return ProcessorWrapper.isSupported && BackgroundTransformer.isSupported;
}

export async function setTrackOptions(localVideoTrack, localAudioTrack, options, rtcProvider, room) {

	// console.log('setTrackOptions',options,localVideoTrack);

	if (localVideoTrack) {

		if (rtcProvider == 'livekit') {

			let currentProcessor = localVideoTrack.getProcessor();
			if (currentProcessor) currentProcessor = currentProcessor.name;

			if ((currentProcessor != 'background-blur') && options.blurBackground) {
				// console.log('adding BackgroundBlur');
				const blur = BackgroundBlur(14);
				await localVideoTrack.setProcessor(blur);
			} else if (currentProcessor && !options.blurBackground) {
				await localVideoTrack.stopProcessor();
			}

			// try using room.switchActiveDevice instead??

			let currentVideoDeviceId = await localVideoTrack.getDeviceId();
			console.log('videoDevice', currentVideoDeviceId, options.videoDevice);

			if (currentVideoDeviceId != options.videoDevice) {
				// let success = await localVideoTrack.setDeviceId(options.videoDevice);
				// if (success) console.log('video device change succeeded');
				// if (room) {
				// 	setTimeout(async () => {
				// 		await room.localParticipant.republishAllTracks();
				// 	}, 400);
				// }
				if (room) {
					await room.switchActiveDevice('videoinput', options.videoDevice);
				} else {
					await localVideoTrack.setDeviceId(options.videoDevice);
				}
			}

			let currentAudioDeviceId = await localAudioTrack.getDeviceId();
			console.log('audioDevice', currentAudioDeviceId, options.audioDevice);

			if (currentAudioDeviceId != options.audioDevice) {
				// let success = await localAudioTrack.setDeviceId(options.audioDevice);
				// if (success) console.log('audio device change succeeded');
				// if (room) {
				// 	setTimeout(async () => {
				// 		await room.localParticipant.republishAllTracks();
				// 	}, 400);
				// }
				if (room) {
					await room.switchActiveDevice('audioinput', options.audioDevice);
				} else {
					await localAudioTrack.setDeviceId(options.audioDevice);
				}
			}

		} else {

			if (localVideoTrack.processor) {
				localVideoTrack.removeProcessor(localVideoTrack.processor);
			}

			if (options.blurBackground) {

				const pipeline = Twilio.VideoProcessors.Pipeline.WebGL2;

				const addProcessorOptions = {
					inputFrameBufferType: 'video',
					outputFrameBufferContextType: 'webgl2',
				};

				const bg = new Twilio.VideoProcessors.GaussianBlurBackgroundProcessor({
					assetsPath: '/twilio/videoProcessors/2.0.0/',
					maskBlurRadius: 40,
					blurFilterRadius: 100,
					pipeline,
		      	debounce: true,
				});

				await bg.loadModel();

				localVideoTrack.addProcessor(bg, addProcessorOptions);

			}

		}

	}

	if (rtcProvider == 'twilio') {
		if (localAudioTrack && localAudioTrack.noiseCancellation) {
			if (options.noiseCancellation) {
				localAudioTrack.noiseCancellation.enable();
			} else {
				localAudioTrack.noiseCancellation.disable();
			}
		}
	}

	if (localAudioTrack && (rtcProvider == 'livekit')) {

		let currentDeviceId = await localAudioTrack.getDeviceId();
		// console.log('audioDevice', currentDeviceId, options.audioDevice);

		if (currentDeviceId != options.audioDevice) {
			await localAudioTrack.setDeviceId(options.audioDevice);
		}

	}

}

export async function getMediaDevices() {
	let videoDevices = [];
	let audioDevices = [];
	const mediaDevices = await navigator.mediaDevices.enumerateDevices();
	// console.log({mediaDevices});
	mediaDevices.forEach((d) => {
		if ((d.kind == 'videoinput') && d.deviceId) {
			videoDevices.push({
				value: d.deviceId,
				label: d.label
			});
		} else if ((d.kind == 'audioinput') && d.deviceId) {
			audioDevices.push({
				value: d.deviceId,
				label: d.label
			});
		}
	});
	return [videoDevices, audioDevices];
}