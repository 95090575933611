<script>

	import { onMount, onDestroy, getContext, tick } from 'svelte';

	import { ParticipantEvent, Track } from 'livekit-client';

	import { attendee } from '../../../lib/stores.js';

	const room = getContext('room');

	export let participant;
	export let i;

	let mounted = false;
	let screenShare = false;
	let attendeeId;
	let feed;
	let audioTrack;
	let audioElement;
	let subscribed = false;

	let sid = '';

	onMount(async () => {
		mounted = true;
	});

	onDestroy(() => {
		if (audioTrack) {
			if (audioTrack.track && audioElement) {
				audioTrack.track.detach(audioElement);
			}
		}
		if (feed) {
			feed.removeListener(ParticipantEvent.TrackSubscribed, trackSubscribed);
		}
	});

	async function rtcTracks(recursed) {

		await tick();

		if (!feed) {
			if ((participant.id != $attendee.ref) && (participant.id.substring(2) != $attendee.ref)) {

				if (participant.id.startsWith('s_')) {
					screenShare = true;
					attendeeId = participant.id.substring(2);
				} else {
					attendeeId = participant.id;
				}

				feed = $room.getParticipantByIdentity(attendeeId);

				// 22 Jan 2025 -- the following helps in the webinput, might be useful here too?
				// if the above fails, wait a moment and then try again
				// but only try once!
				if (!feed && !recursed) {
					setTimeout(() => {
						rtcTracks(true);
					}, 400);
				}

				if (feed) {

					feed.on(ParticipantEvent.TrackSubscribed, trackSubscribed);

					let publications = Array.from(feed.trackPublications.values());

					publications.forEach(t => {
						trackInit(t);
					});

				}

			}
		}

	}

	async function trackInit(track) {
		if ((screenShare && (track.source == "screen_share_audio")) || (!screenShare && (track.source == "microphone"))) {
			audioTrack = track;
			audioTrack.setEnabled(false);
			if (audioTrack.isSubscribed) {
				trackSubscribed(audioTrack.track);
			}
		}
	};

	async function trackSubscribed(track) {
		if ((screenShare && (track.source == "screen_share_audio")) || (!screenShare && (track.source == "microphone"))) {
			subscribed = true;
			await tick();
			track.attach(audioElement);
		}
	};

	async function setMuted() {
		if (participant.o && !participant.m) {
			audioTrack.setEnabled(true);
		} else {
			audioTrack.setEnabled(false);
		}
	}

	function checkSid() {
		if (participant.t != sid) {
			sid = participant.t;
			if (mounted && $room) {
				rtcTracks();
			}
		}
	}

	$: if (mounted && $room) {
		rtcTracks();
	}

	$: checkSid(participant);

	$: if (participant && audioElement && audioTrack && subscribed) {
		setMuted();
	}

</script>

<!-- <style>
	.sid {
		position: absolute;
		z-index: 100000;
		top: 20px;
		left: 20px;
		color: #fff;
		font-size: 20px;
	}
	.sid.num1 {
		top: 50px;
	}
</style> -->

{#key sid}
	<audio id={'audio-' + participant.id} bind:this={audioElement} />
	<!-- <div class="sid num{i}">{sid}</div> -->
{/key}
