<script>

	import { onMount, onDestroy, createEventDispatcher } from 'svelte';
	import { fade } from 'svelte/transition';

	import { videoCall, overlays } from '../lib/stores.js';

	const dispatch = createEventDispatcher();

	export let modal = false;
	export let tall = false;
	export let narrow = false;
	export let wide = false;
	export let extrawide = false;
	export let isVideoCall = false;
	export let nested = false;
	export let spill = false;

	onMount(() => {
		$overlays++;
	});

	onDestroy(() => {
		$overlays--;
	});

	function handleKeydown(event){
		if (!modal) {
			if (event.key === 'Escape') {
				dispatch('escape');
			}
		}
	}

	function backgroundClick() {
		if (!modal) {
			dispatch('escape');
		}
	}

	function autoClose() {
		if (!isVideoCall) {
			dispatch('escape');			
		}
	}

	$: if ($videoCall) {
		autoClose();
	}

</script>

<style>

	/*.overlay {
		position: fixed;
		inset: 0;
		z-index: 12001;
	}*/

	.overlay {
		position: fixed;
		inset: 0;
		display: grid;
		place-content: center;
		z-index: 12002;
/*		backdrop-filter: blur(2px);*/
	}

	.overlay:before {
		content: '';
		position: absolute;
		inset: 0;
		background: var(--textColor);
		/*opacity: 0.6;*/
		opacity: 0.7;
	}

	.overlay > div {
		max-width: 85vw;
		width: 560px;
		box-sizing: border-box;
		background: var(--panelColor);
		color: var(--textColor);
		box-shadow: 0 20px 80px var(--shadow);
		position: relative;
		border-radius: 4px;
		position: relative;
		display: flex;
		flex-direction: column;
		transition: width 0.4s ease, transform 0.2s ease, filter 0.2s ease;
	}

	.overlay.narrow > div {
		/*width: 460px;*/
		/*width: 27rem;*/
		width: clamp(30vw, 25rem, 80vw);
	}

	.overlay.wide > div {
		width: clamp(40vw, 40rem, 90vw);
	}

	.overlay.extrawide > div {
		width: clamp(60vw, 60rem, 90vw);
	}

	.overlay.vc > div {
		width: auto;
		border-radius: 8px;
	}

	.overlay.nested > div {
		transform: scale(0.8);
		filter: blur(2px);
	}

	.overlay .close {
		position: absolute;
		top: 0;
		right: 0;
		width: 3rem;
		height: 3rem;
		background: transparent;
		color: var(--textColor);
		border: 0;
		z-index: 20000;
	}
	.overlay .close:hover,
	.overlay .close:active,
	.overlay .close:focus {
		color: var(--accentColor);
	}
	.overlay .close svg {
		position: absolute;
		inset: 0.5rem;
	}

	.overlay :global(.overlay-title) {
		flex: 0 0 auto;
		padding: 2rem 3rem;
	}

	.overlay :global(.overlay-title h2) {
		margin: 0;
		font-size: 1rem;
	}

	.overlay :global(.overlay-title h2 + p) {
		margin: 0.5rem 0 0 0;
		/*font-size: 0.6875rem;*/
		font-size: 0.8rem;
	}

	.overlay :global(.overlay-main) {
		flex: 1 0 auto;
		overflow-y: auto;
		padding: 1rem 3rem;
		max-height: calc(80vh - 8rem);
		max-height: calc(80dvh - 8rem);
		background: var(--blend-10);
	}

	.overlay.spill :global(.overlay-main) {
		overflow-y: visible;
	}

	.overlay.tall > div {
		height: 90vh;
		height: 90dvh;
	}

	.overlay.tall :global(.overlay-main) {
		max-height: unset;
		flex: 1 1 auto;
	}

	.overlay :global(.overlay-actions) {
		flex: 0 0 auto;
		display: flex;
		gap: 1rem;
		padding: 1.4rem 3rem;
	}

	@media (max-width: 600px) {

		.overlay :global(.overlay-title) {
			padding: 2rem 2rem;
		}

		.overlay :global(.overlay-main) {
			padding: 1rem 2rem;
		}

		.overlay :global(.overlay-actions) {
			padding: 1.4rem 2rem;
		}

	}

</style>

<svelte:window on:keydown|stopPropagation={handleKeydown}/>

<!-- 18 Dec 2024 -- is it possible that the out:fade={{ duration: 100 }} we had here
	is sometimes preventing the overlay from actually closing?
	Removing it to see... -->
<!-- svelte-ignore a11y-click-events-have-key-events -->
<div
	class="overlay"
	in:fade={{ duration: 200, delay: 100 }}
	on:click={backgroundClick}
	class:tall
	class:narrow
	class:wide
	class:extrawide
	class:vc={isVideoCall}
	class:nested
	class:spill
>
	<div on:click|stopPropagation>
		{#if !isVideoCall}
			<button type="button" class="close" on:click={() => { dispatch('escape') }}>
				<svg viewBox="0 0 80 80"><title>Close</title><path d="M40.06 44.53l-11 11-4.5-4.49 11-11-11-11 4.5-4.5 11 11 11-11 4.5 4.49-11 11 11 11-4.5 4.49-11-10.99z"/></svg>
			</button>
		{/if}
		<slot/>
	</div>
</div>